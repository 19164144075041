<template>
  <div class="inpatient_detail_container">
    <div class="header">日费用总金额：{{ hospitalizationFeesList.totalFee }}元</div>
    <div class="table">
      <van-row class="thead">
        <van-col span="6">项目名称</van-col>
        <van-col span="5">类别</van-col>
        <van-col span="8">单位(元)/数量</van-col>
        <van-col span="5">总金额(元)</van-col>
      </van-row>
      <van-row class="tr" v-for="(item, index) in hospitalizationFeesList.item" :key="index">
        <van-col span="6">{{ item.itemName }}</van-col>
        <van-col span="5">{{ item.itemType }}</van-col>
        <van-col span="8">{{ item.itemPrice }}/{{ item.itemNumber }}*{{ item.itemUnit }}</van-col>
        <van-col span="5">{{ item.itemTotalFee }}</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      query: {},
      hospitalizationFeesList: {}
    };
  },
  created () {
    this.query = this.$route.query;
    this.getHospitalizationFeesList();
  },
  methods: {
    async getHospitalizationFeesList () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalizationFeesList",
        {
          patCardType: this.query.patCardType,
          patCardNo: this.query.patCardNo,
          admissionNum: this.query.admissionNum,
          beginDate: this.query.itemDate,
          endDate: this.query.itemDate
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.hospitalizationFeesList = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style scope>
.inpatient_detail_container {
  padding-bottom: 60px;
}

.inpatient_detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 250px;
  background-color: #f5f5f5;
  font-size: 50px;
  text-align: center;
  line-height: 250px;
}

.inpatient_detail_container .table {
  font-size: 36px;
  text-align: center;
}

.inpatient_detail_container .table .thead,
.inpatient_detail_container .table .tr {
  padding: 20px 10px;
  border-bottom: 1px solid #f5f5f5;
}
</style>

